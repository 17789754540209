<template>
  <div style="text-align: justify">
    <p class="mb-6">
      The following data were collected during the titration of a solid sample of potassium acid
      phthalate ("KHP") with a solution of sodium hydroxide. The NaOH solution was added to the KHP
      from a buret.
    </p>

    <p class="mb-6">
      From the experimental data give below, calculate the <b>molarity</b> of the NaOH solution.
    </p>

    <p class="mb-5">
      <u>Data:</u>
    </p>

    <v-simple-table style="margin-left: 50px; margin-bottom: 50px">
      <tbody>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Molar mass of potassium acid phthalate }$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$=\,\,\,204.23\text{ g/mol}$" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Mass of weighing bottle plus KHP}$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$=\,\,\,$" />
            <latex-number :number="bottleAndKHP" />
            <stemble-latex content="$\text{g}$" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Mass of weighing bottle}$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$=\,\,\,$" />
            <number-value :value="bottle" unit="\text{g}" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Initial Buret Reading}$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$=\,\,\,$" />
            <number-value :value="Vi" unit="\text{mL}" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Final Buret Reading}$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$=\,\,\,$" />
            <number-value :value="Vf" unit="\text{mL}" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'UPEISpectroscopePreLab',
  components: {
    StembleLatex,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  computed: {
    KHP() {
      return this.taskState.getValueBySymbol('KHP').content;
    },
    bottle() {
      return this.taskState.getValueBySymbol('bottle').content;
    },
    Vi() {
      return this.taskState.getValueBySymbol('Vi').content;
    },
    Vf() {
      return this.taskState.getValueBySymbol('Vf').content;
    },
    bottleAndKHP() {
      return (this.KHP.toFloat() + this.bottle.toFloat()).toFixed(4);
    },
  },
};
</script>
